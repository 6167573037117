import React from "react";
import "../styles/Projects.css";

function Projects() {
  return (
    <div className="projects">
      <h1>Selected Projects</h1>
      <div className="projectList">
        <h2>Under Construction ...</h2>
      </div>
    </div>
  );
}

export default Projects;
